<script setup>
import { reactive, defineExpose,nextTick ,getCurrentInstance,defineEmits} from "vue";
import { ElMessage } from 'element-plus'
const { appContext } = getCurrentInstance()
const emit = defineEmits(['refresh'])

const inputstyle = "width:100%"
const data = reactive({
    isshow:false,
    merchant:{
        agent_id:"",
        salesman_id:"",
        merchant_id:[]
    },
    option:[],
    salesman:[],
    merchant_ids:[]
})

const open = (item) => {
    data.isshow = true
    nextTick(() => {
        data.merchant_ids=item
        queryAgents()
    })
}

// 提交
const hanldeSubmit=()=>{
    data.merchant.merchant_id=Object.values(data.merchant_ids)
    request(data.merchant)
    // console.log(data.merchant);
}

// 查询代理商
const queryAgents=()=>{
    appContext.config.globalProperties.$api.agentsList({pageSize:"999"}).then(res=>{
    // appContext.config.globalProperties.$api.agentsList({parent_id:'null',pageSize:999}).then(res => {
        if (res.status==200) {
            // data.option=res.data
            data.option=treeToArray(res.data)
        }
    })
}

const treeToArray=(tree)=>{
    const res = [] 
    let list = {}
    for(const item of tree){
        list[item.id] ={...item,value:item.id,label:item.company,children:[]}
    }
    for(const item of tree){
      const newItem=list[item.id]
      if (item.parent_id===null) {
        res.push(newItem)
      }else{
        if(Object.prototype.hasOwnProperty.call(list,item.parent_id)){
          list[item.parent_id].children.push(newItem)
        }
      }
    }
    return res
}


// 查询业务员
function querySalesmanList(value){
  appContext.config.globalProperties.$api.salesman.list({page:1,pageSize:999,agent_id:value}).then(res => {
    if (res.status == 200) {
        data.salesman=res.data.data
    }
  })
}

function request(req){
    appContext.config.globalProperties.$api.merchants.change(req).then(r=>{
        if (r.status==200) {
            ElMessage({message: '商户转移成功', type: 'success'})
            data.isshow = false
            emit('refresh')
        }else{
            ElMessage.error('商户转移失败')
        }
    })
}

const handleCascaderChange=(value)=>{
    console.log(value);
    querySalesmanList(value[value.length -  1])
    data.merchant.agent_id=value[value.length - 1]
}

defineExpose({ open })

</script>

<template>
  <el-dialog v-model="data.isshow" width="26%">
    <template #header>
        商户转移    
    </template>
    <el-form :model="data.merchant">
        <el-form-item label="代理商:">
            <!-- <el-select v-model="data.merchant.agent_id" placeholder="请选择代理商" filterable remote reserve-keyword  @change="querySalesmanList" :style=inputstyle>
                <el-option  v-for="(item,index) in data.option" :key="index"  :label="item.company"   :value="item.id" />
            </el-select> -->
            <!-- <el-cascader v-model="data.merchant.agent_id" :options="data.option" @change="handleCascaderChange" :props="{checkStrictly: true}" /> -->
            <el-cascader v-model="data.merchant.agent_id" :options="data.option" @change="handleCascaderChange" :props="{checkStrictly: true}" :style=inputstyle>
                <template #default="{ node, data }">
                    <span>{{ data.label }}</span>
                    <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
                </template>
            </el-cascader>
        </el-form-item>
        <el-form-item label="业务员:">
            <el-select v-model="data.merchant.salesman_id" placeholder="请选择业务员" filterable remote reserve-keyword :style=inputstyle>
                <el-option  v-for="(item,index) in data.salesman" :key="index"  :label="item.name + '-'+ item.phone" :value="item.id" />
            </el-select>
        </el-form-item>
    </el-form>
    <template #footer>
        <el-button @click="data.isshow = false">取消</el-button>
        <el-button type="primary" @click="hanldeSubmit">确定</el-button>
    </template>
  </el-dialog>
</template>

<style lang="less" scoped>

</style>